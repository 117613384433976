// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Snackbar.css */
.snackbar {
  max-width: 90%;
  font-size: 1rem;
  transition: font-size 0.3s ease;
  border-width: 2px;
  border-style: solid;
}

@media (min-width: 768px) {
  .snackbar {
    max-width: 60%;
    font-size: 1.25rem; /* Make snackbar bigger on desktop */
  }
}

.border-green-500 {
  border-color: #48bb78; /* Tailwind green-500 */
}

.border-orange-500 {
  border-color: #ed8936; /* Tailwind orange-500 */
}

.border-red-500 {
  border-color: #f56565; /* Tailwind red-500 */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Snackbar.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,cAAc;EACd,eAAe;EACf,+BAA+B;EAC/B,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB,EAAE,oCAAoC;EAC1D;AACF;;AAEA;EACE,qBAAqB,EAAE,uBAAuB;AAChD;;AAEA;EACE,qBAAqB,EAAE,wBAAwB;AACjD;;AAEA;EACE,qBAAqB,EAAE,qBAAqB;AAC9C","sourcesContent":["/* Snackbar.css */\n.snackbar {\n  max-width: 90%;\n  font-size: 1rem;\n  transition: font-size 0.3s ease;\n  border-width: 2px;\n  border-style: solid;\n}\n\n@media (min-width: 768px) {\n  .snackbar {\n    max-width: 60%;\n    font-size: 1.25rem; /* Make snackbar bigger on desktop */\n  }\n}\n\n.border-green-500 {\n  border-color: #48bb78; /* Tailwind green-500 */\n}\n\n.border-orange-500 {\n  border-color: #ed8936; /* Tailwind orange-500 */\n}\n\n.border-red-500 {\n  border-color: #f56565; /* Tailwind red-500 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
