import { useEffect } from "react";
import { EXPLORER_BASE_URL } from "../constants";


export enum SnackBarStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export const Snackbar: React.FC<{ message: string, onClose: () => void, txHash?: string, status?: SnackBarStatus }> = ({ message, onClose, txHash, status }) => {
  if (!message) return null;

  useEffect(() => {
    const timer = setTimeout(onClose, 8000); // Increased duration to 5 seconds for better visibility
    return () => clearTimeout(timer);
  }, [onClose]);

  const handleClick = () => {
    if (txHash) {
      window.open(`${EXPLORER_BASE_URL}/tx/${txHash}`, '_blank');
    }
  };

  // Determine the border color based on the status
  const getBorderColor = (status: SnackBarStatus | undefined) => {
    switch (status) {
      case SnackBarStatus.SUCCESS:
        return 'border-green-500';
      case SnackBarStatus.WARNING:
        return 'border-yellow-500';
      case SnackBarStatus.ERROR:
        return 'border-red-500';
      default:
        return 'border-gray-800';
    }
  };

  return (
    <div
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded shadow-lg cursor-pointer snackbar ${getBorderColor(status)}`}
      onClick={handleClick}
    >
      {message} {txHash && <span className="underline">{txHash.slice(0,15)}...{txHash.slice(-15)}</span>}
    </div>
  );
};
