import React, { useEffect, useState } from "react";
import { BUSINESS_ID, COUNTER_ADDRESS } from "../constants";
import { plentifi, provider, SmartAccount, SupportedChains, UserOp } from "@plentifi/smartaccountsdk";
import { Contract } from "ethers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './DemoIntegration.css';

import './Snackbar.css';
import { Snackbar, SnackBarStatus } from "./Snackbar";



const DemoIntegration: React.FC = () => {
  const [account, setAccount] = useState<SmartAccount | null>(null);
  const [lastCaller, setLastCaller] = useState('');
  const [counterValue, setCounterValue] = useState<number>(0);
  // const [differentCallers, setDifferentCallers] = useState<number>(0);
  const [isIncrementing, setIsIncrementing] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : true;
  });
  const [showCode, setShowCode] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const [snackBarHash, setSnackBarHash] = useState<string | undefined>(''); // State for transaction hash
  const [chainId, setChainId] = useState(SupportedChains.DEFAULT);
  const [counter, setCounter] = useState<Contract | null>(null);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    // If the path is /demo then set the chainId to DEFAULT. Otherwise, set it to POLYGON_AMOY
    let chain = SupportedChains.DEFAULT;
    // if (window.location.pathname !== '/demo') {
    //   chain = SupportedChains.POLYGON_AMOY;
    // }
    const counterContract = new Contract(COUNTER_ADDRESS, ['function increment() public', 'function getCount() public view returns (uint256)'], provider(BUSINESS_ID, chainId));
    setCounter(counterContract);
    setChainId(chain);

    (async () => {
      await fetchCounterValue(chain);
    })();
  }, []);

  const fetchCounterValue = async (chainId: string) => {
    const counter = new Contract(COUNTER_ADDRESS, ['function getCount() public view returns (uint256)', { "type": "function", "name": "differentCallers", "inputs": [], "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }], "stateMutability": "view" }], provider(BUSINESS_ID, chainId));
    const value = await counter.getCount();
    // const callers = await counter.differentCallers();
    // console.log(" differentCallers: ", callers.toNumber());
    // setDifferentCallers(callers.toNumber());
    setCounterValue(value.toNumber());
  };

  // every 2 minutes, fetch the counter value
  useEffect(() => {

    const interval = setInterval(async () => {
      await fetchCounterValue(chainId);
      // console.log('Counter value updated');
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  const handleIncrement = async () => {
    if (isIncrementing) return;
    if (!counter) return;

    setIsIncrementing(true);

    // setup the user operation
    const userOp: UserOp = {
      targetAddress: counter.address,
      value: '0',
      callData: counter.interface.encodeFunctionData('increment'),
      chainId: SupportedChains.ARB_SEPOLIA,
    };
    // console.log('userOp:', userOp);

    // connect the user account
    try {
      const { smartAccount, txResponse, username } = await plentifi.connect(BUSINESS_ID, SupportedChains.ARB_SEPOLIA, userOp);
      console.log('txResponse: ', txResponse);

      if (txResponse?.transactionHash) {
        setAccount(smartAccount);
        setLastCaller(username);
        await fetchCounterValue(chainId);
        setSnackBarMsg("Transaction successful: ");
        setSnackBarHash(txResponse.transactionHash); // Set the transaction hash
      } else {
        // throw new Error('No transaction hash found');
        setSnackBarMsg("Transaction failed");
      }
    } catch (error) {
      console.error(error);
      if (String(error).includes('Popup closed by user')) {
        setSnackBarMsg("User rejected the request");
      } else {
        setSnackBarMsg("Transaction failed!");
      }
      setSnackBarHash(undefined); // Clear the transaction hash
    }
    setIsIncrementing(false);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const codeString = `// connect the user account 
const { smartAccount, username } = await plentifi.connect(BUSINESS_ID, SupportedChains.ARB_SEPOLIA);

// send a transaction
const txResponse = await smartAccount.execute(userOperation);
`;

  const getSuffix = (value: number) => {
    const lastDigit = value % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-dark-blue text-white' : 'bg-light text-black'}`}>
      {/* Navigation Bar */}
      <nav className="p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold">PlentiFi</div>
          <button onClick={toggleDarkMode} className={`w-8 h-8 flex items-center justify-center rounded-lg border ${darkMode ? 'bg-dark-blue border-white' : 'bg-light border-black'} mx-2 focus:outline-none`}>
            <FontAwesomeIcon icon={darkMode ? faMoon : faSun} className={`w-4 h-4 ${darkMode ? 'text-white' : 'text-black'}`} />
          </button>
        </div>
      </nav>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center p-6">
        <Snackbar message={snackBarMsg} onClose={() => setSnackBarMsg('')} txHash={snackBarHash} status={snackBarHash ? SnackBarStatus.SUCCESS : SnackBarStatus.ERROR} /> {/* Pass txHash to Snackbar */}
        <div className="text-center space-y-8">
          <p className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {account && lastCaller
              ? `Congratulations ${lastCaller}, you're the ${counterValue}${getSuffix(counterValue)} person to use PlentiFi !`
              : `${counterValue} people have already used PlentiFi, take the leap !`}
          </p>
          <div className="flex justify-center">
            <button
              onClick={handleIncrement}
              className="w-full max-w-xs px-6 py-3 bg-orange text-dark-blue rounded-full flex items-center justify-center space-x-6 hover:bg-orange-dark transition duration-300"
            >
              <span className="whitespace-nowrap text-lg">{account ? "Use PlentiFi" : "Get started with PlentiFi.app"}</span>
              {isIncrementing && (
                <div>
                  <svg className="animate-spin h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                </div>
              )}
              {!isIncrementing && (
                <FontAwesomeIcon icon={faArrowRight} className="ml-3 text-xl" />
              )}
            </button>
          </div>

          <p className="text-gray-400 text-lg">(This demo is live on testnet only)</p>

          <div className="flex justify-center mt-6">
            <button
              onClick={() => setShowCode(!showCode)}
              className={`px-6 py-3 w-auto flex items-center justify-center rounded-lg border ${darkMode ? 'bg-dark-blue border-white' : 'bg-light border-black'} mx-2 focus:outline-none transition duration-300`}
            >
              <span className="text-lg">Code snippet</span>
              <FontAwesomeIcon icon={showCode ? faArrowDown : faArrowRight} className="ml-3 text-lg" />
            </button>
          </div>
        </div>
        <div className={`snippet-container ${showCode ? 'show' : 'hide'}`}>
          <div className={`w-full mt-6 p-6 ${darkMode ? 'bg-gray-800' : 'bg-light'} rounded-lg`}>
            <SyntaxHighlighter language="typescript" style={okaidia} customStyle={{ fontSize: '1rem', padding: '1rem' }}>
              {codeString}
            </SyntaxHighlighter>
          </div>
        </div>
      </main>
      {/* Footer */}
      <footer className="flex items-center justify-center p-4">
        <div className="flex justify-center space-x-4">
          <a href="#"><svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2a10 10 0 00-3.161 19.476c.5.093.682-.217.682-.481v-1.687c-2.779.604-3.368-1.344-3.368-1.344-.455-1.157-1.111-1.466-1.111-1.466-.908-.62.068-.607.068-.607 1.003.071 1.531 1.03 1.531 1.03.892 1.53 2.341 1.088 2.913.832.091-.647.35-1.087.636-1.336-2.219-.253-4.555-1.11-4.555-4.942 0-1.09.39-1.981 1.03-2.679-.103-.253-.447-1.271.098-2.649 0 0 .84-.269 2.75 1.027a9.56 9.56 0 012.5-.337 9.55 9.55 0 012.5.337c1.91-1.296 2.75-1.027 2.75-1.027.545 1.378.201 2.396.098 2.649.64.698 1.03 1.589 1.03 2.679 0 3.842-2.337 4.685-4.565 4.934.36.31.678.924.678 1.859v2.754c0 .268.18.578.687.48A10 10 0 0012 2z" /></svg></a>
          <a href="#"><svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.271c-.966 0-1.75-.785-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 11.271h-3v-5.41c0-1.289-.025-2.946-1.796-2.946-1.796 0-2.07 1.404-2.07 3.025v5.331h-3v-10h3v1.378c.648-.995 2.25-2.239 3.868-2.239 2.856 0 3.502 1.895 3.502 4.348v6.514z" /></svg></a>
          <a href="#"><svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 24 24"><path d="M23.994 4.569c-.882.392-1.83.656-2.825.775 1.015-.609 1.794-1.574 2.163-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-2.717 0-4.917 2.2-4.917 4.917 0 .385.044.761.128 1.122-4.084-.205-7.702-2.161-10.126-5.134-.423.729-.666 1.574-.666 2.475 0 1.708.87 3.215 2.188 4.099-.807-.026-1.566-.248-2.228-.617v.062c0 2.385 1.697 4.374 3.946 4.828-.414.112-.852.171-1.302.171-.319 0-.63-.031-.934-.089.631 1.966 2.465 3.396 4.637 3.436-1.699 1.33-3.835 2.122-6.156 2.122-.399 0-.793-.023-1.182-.068 2.192 1.405 4.795 2.225 7.595 2.225 9.11 0 14.092-7.546 14.092-14.092 0-.215-.005-.429-.015-.642.966-.696 1.8-1.56 2.462-2.549z" /></svg></a>
        </div>
      </footer>
    </div>
  );
};

export default DemoIntegration;
