// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snippet-container {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.snippet-container.show {
  max-height: 1000px;
  opacity: 1;
}

/* Additional CSS for better snippet handling */
.snippet-container {
  width: 100%;
}

.snippet-container .show {
  display: block;
}

.snippet-container .hide {
  display: none;
}

/* Ensure code snippet adapts to screen size */
.snippet-container pre {
  white-space: pre-wrap; /* Ensures code wraps instead of overflowing */
  word-wrap: break-word; /* Ensures long words break appropriately */
}

/* Apply width 100% on mobile devices */
@media (max-width: 767px) {
  .snippet-container {
    width: 100%;
  }
}

/* Remove width 100% on larger screens */
@media (min-width: 768px) {
  .snippet-container {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/DemoIntegration.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,gBAAgB;EAChB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA,+CAA+C;AAC/C;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA,8CAA8C;AAC9C;EACE,qBAAqB,EAAE,8CAA8C;EACrE,qBAAqB,EAAE,2CAA2C;AACpE;;AAEA,uCAAuC;AACvC;EACE;IACE,WAAW;EACb;AACF;;AAEA,wCAAwC;AACxC;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".snippet-container {\n  transition: max-height 0.5s ease, opacity 0.5s ease;\n  overflow: hidden;\n  max-height: 0;\n  opacity: 0;\n}\n\n.snippet-container.show {\n  max-height: 1000px;\n  opacity: 1;\n}\n\n/* Additional CSS for better snippet handling */\n.snippet-container {\n  width: 100%;\n}\n\n.snippet-container .show {\n  display: block;\n}\n\n.snippet-container .hide {\n  display: none;\n}\n\n/* Ensure code snippet adapts to screen size */\n.snippet-container pre {\n  white-space: pre-wrap; /* Ensures code wraps instead of overflowing */\n  word-wrap: break-word; /* Ensures long words break appropriately */\n}\n\n/* Apply width 100% on mobile devices */\n@media (max-width: 767px) {\n  .snippet-container {\n    width: 100%;\n  }\n}\n\n/* Remove width 100% on larger screens */\n@media (min-width: 768px) {\n  .snippet-container {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
