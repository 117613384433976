export const BASE_BACKEND_URL = "https://api-dev-test-prealpha.plentifi.app";
export const RELAY_API = "/api/signRelay";
export const POST_RELAY = "/api/relaySignedData";

export const BUSINESS_ID = "0123456789";

export const COUNTER_ADDRESS =  /* window.location.pathname === '/demo' ? "0x8b37aF9a23E344c8AA4BCc945b65DDcB35D19628" : ""; */ "0x6b03a268ec21FE1c3AbC8f318011BBA641871c84";

export const EXPLORER_BASE_URL =  /* window.location.pathname === '/demo' ? "https://sepolia.arbiscan.io/" : "https://amoy.polygonscan.com/"; */ "https://sepolia.arbiscan.io/";


